<template>
	<v-container fluid>
		<v-layout align-center justify-center>
			<v-flex xs12 sm12 md12>
				<v-tabs
					v-model="tab"
					fixed-tabs
					background-color="indigo"
					dark
				>
					<v-tab>
						NHẬP XUẤT KHO ĐỐI TÁC
					</v-tab>
					<v-tab>
						TOM ORDER
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-row>
							<v-col
								cols="12"
								xs12 sm8 md6
							>
								<v-card flat>
									<v-card-title>
										<v-toolbar flat color="#0f2e8c">
											<h3 class="white--text">NHẬP XUẤT KHO ĐỐI TÁC</h3>
										</v-toolbar>
									</v-card-title>
									<v-card-text>
										<v-row>
											<v-col cols="auto">
												<v-text-field
													ref="Tracking"
													id="Tracking"
													class="mx-2"
													v-model="Tracking"
													label="Mã vận đơn"
													@change="doSearch"
												/>
											</v-col>
										</v-row>
										<template v-if="packageData !== false && packageData !== undefined">
											<p>Khách hàng: <strong>{{packageData.Customer.Username}}</strong></p>
											<p>ID Order: <strong>{{packageData.Order}}</strong></p>
											<p>ID Sản phẩm: <strong>{{packageData.Product}}</strong></p>
											<br />
											<template v-if="packageData !== false && packageData.Status === 'cho_nhap_kho'">
												<v-row>
													<v-col cols="auto">
														<v-text-field
															v-model="packageData.D"
															label="Dài"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="packageData.R"
															label="Rộng"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="packageData.C"
															label="Cao"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="packageData.KL"
															label="Khối lượng"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="packageData.PhiShipNoiDia"
															label="Phí ship nội địa"
														/>
													</v-col>
												</v-row>
												<br />
												<v-btn
													class="mx-2"
													color="blue"
													@click="doImport"
													dark
												>
													Nhập kho
												</v-btn>
											</template>
											<v-btn
												class="mx-2"
												color="blue"
												@click="doExport"
												dark
												v-if="packageData !== false && packageData.Status === 'da_nhap_kho'"
											>
												Xuất kho
											</v-btn>
											<Loading :status="importLoad" />
										</template>
										<template v-if="packageData === false">
											<p>Không tìm thấy dữ liệu</p>
											<br />
											Bạn có thể nhập mã vận đơn trên vào hàng chờ nhập kho !!!
											<br />
												<v-row>
													<v-col cols="auto">
														<v-text-field
															v-model="pendingData.D"
															label="Dài"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="pendingData.R"
															label="Rộng"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="pendingData.C"
															label="Cao"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="pendingData.KL"
															label="Khối lượng"
														/>
													</v-col>
													<v-col cols="auto">
														<v-text-field
															v-model="pendingData.PhiShipNoiDia"
															label="Phí ship nội địa"
														/>
													</v-col>
													<v-col cols="auto">
														<v-textarea
															v-model="pendingData.Notes"
															label="Ghi chú"
														/>
													</v-col>
												</v-row>
											<br />
											<v-btn
												color="blue"
												@click="doAddWaitList"
												dark
												small
											>
												Nhập vào hàng chờ
											</v-btn>
										</template>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-tab-item>
					<v-tab-item>
						<v-card>
							<v-card-text>
								<v-row>
									<v-col cols="12" sm="12" md="12" lg="12" xl="12">
										<v-text-field
											ref="TrackingTO"
											id="TrackingTO"
											class="mx-2"
											v-model="Tracking"
											label="Mã vận đơn"
											append-icon="search"
											@change="doSearchTracking"
										/>
									</v-col>
								</v-row>
								<br />
								<v-data-table
									:headers="headersTO"
									:items="itemsTO"
									:loading="loaderTO"
									:expanded.sync="expandedTO"
									:single-expand="false"
									item-key="_id"
									show-expand
									class="elevation-1"
								>
									<template v-slot:[`item.Print`]="{ item }">
										<v-btn
											color="success"
											@click="doPrintTO(item)"
										>
											In
										</v-btn>
									</template>
									<template v-slot:[`item.Import`]="{ item }">
										<template v-if="item.Imported === false && item.Items.filter(f=>f['Tracking'] === Tracking && f['Imported'] === false).length > 0">
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														color="blue"
														dark
														v-bind="attrs"
														v-on="on"
													>
														Nhập kho
													</v-btn>
												</template>
												<v-list>
													<v-list-item>
														<v-list-item-title>
															<v-btn
																color="green"
																dark
																small
																@click="doImportOrder(item,'air')"
															>
																Air
															</v-btn>
														</v-list-item-title>
													</v-list-item>
													<v-list-item>
														<v-list-item-title>
															<v-btn
																color="blue"
																dark
																small
																@click="doImportOrder(item,'ship')"
															>
																Sea
															</v-btn>
														</v-list-item-title>
													</v-list-item>
												</v-list>
											</v-menu>
										</template>
										<template v-else>
											<v-chip
												color="green"
												dark
											>
												<template v-if="item.Imported === true">
													Đơn hàng đã nhập kho
												</template>
												<template v-else>
													Tracking đã nhập kho
												</template>
											</v-chip>
										</template>
									</template>
									<template v-slot:expanded-item="{ headers, item }">
										<td/>
										<td>
											<template v-if="item['ProductName']">
												<v-list-item
													three-line
												>
													<v-list-item-content>
														<v-list-item-title>
															Khách hàng: {{ item.Customer.Username }}
														</v-list-item-title>
														<v-list-item-subtitle>
															{{ item['ProductName'] }}
														</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
											</template>
											<template v-else>
												<v-list-item
													three-line
												>
													<v-list-item-content>
														<v-list-item-title>
															Khách hàng: {{ item.Customer.Username }}
														</v-list-item-title>
														<v-list-item-subtitle>
															<p>Danh sách hàng hóa</p>
															<template
																v-for="itemData in item.Items"
															>
																<p
																	:key="itemData['_id']"
																	v-if="itemData['Tracking'] === Tracking && itemData['Imported'] === false"
																>
																	{{ itemData['Name'] }} x {{ itemData['Quantity'] }}
																</p>
															</template>
														</v-list-item-subtitle>
													</v-list-item-content>
												</v-list-item>
											</template>
										</td>
									</template>
								</v-data-table>
							</v-card-text>
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-flex>
		</v-layout>
		<Loading :status="loading" />
	</v-container>
</template>

<script>
import Loading from '@/components/base/Loading.vue';
import Query from '@/plugins/query/partner';
import {Status} from '@/plugins/helper/dstatus';
import {mapGetters} from "vuex";
import {doImport, doSearch, getPDF} from "../plugins/query/tomorder";
import printJS from "print-js";

export default {
	name: "ImportPartner",
	components:{
		Loading,
	},
	data(){
		return {
			tab: 0,
			Tracking: "",
			Notes: "",
			packageData: undefined,
			pendingData: {},
			importLoad: false,
			loading: false,
			headersTO:[
				{ text: 'Đơn hàng TO', value: 'Code', align: 'center',width: "80%",class:"text-center grey lighten-2 black--text" },
				{ text: 'In', value: 'Print', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
				{ text: 'Nhập', value: 'Import', align: 'center',width: "10%",class:"text-center grey lighten-2 black--text" },
			],
			itemsTO:[],
			expandedTO:[],
			loaderTO: false,
		}
	},
	computed:{
		...mapGetters(['authStatus','isAuthenticated','loginInfo']),
		notice(){
			return new Status(this.$swal);
		}
	},
	methods:{
		...Query,
		doPrintTO(item){
			if(this.Tracking && item.Customer.Username){
				this.loaderTO = true;
				getPDF(this.Tracking, item.Customer.Username).then(pdfFile=>{
					const pdfUrl = URL.createObjectURL(pdfFile);
					printJS(pdfUrl);
				}).finally(()=>{
					this.loaderTO = false;
				});
			}
		},
		doSearchTracking(){
			if(!this.Tracking) return;
			this.loaderTO = true;
			doSearch(this.Tracking).then(resp=> {
				const {data, error} = resp;
				if(data){
					this.itemsTO = data;
				}else{
					this.notice.error('Không thể tìm thấy dữ liệu !!!');
				}
				if(error){
					this.notice.error(error);
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error('Có lỗi khi xử lí yêu cầu !!!');
			}).finally(()=>{
				this.loaderTO = false;
			})
		},
		doImportOrder(item,ship){
			const {_id} = item;
			if(_id && (ship === 'air' || ship === 'ship')){
				this.loaderTO = true;
				doImport({Order:_id,Shipping:ship, Tracking:this.Tracking}).then(resp=>{
					const {success,error, data} = resp;
					if(success || data) {
						this.notice.success('Đã nhập kho các đơn hàng thuộc mã vận đơn: ' + this.Tracking);
						this.Tracking = null;
						this.itemsTO = [];
						//this.doDownloadAll();
					}else{
						const msg = error || "Không thể nhập kho đơn hàng thuộc mã vận đơn: " + this.Tracking;
						this.notice.error(msg);
						this.Tracking = null;
						this.itemsTO = [];
					}
				}).catch(e=>{
					console.error(e);
					this.notice.error('Có lỗi khi xử lí yêu cầu !!!');
				}).finally(()=>{
					this.loaderTO = false;
				})
			}
		},
		doSearch(){
			if(!this.Tracking){
				this.notice.error('Vui lòng điền mã Tracking để tìm kiếm !!!');
				return;
			}
			this.loading = true;
			this.qrFindTrack(this.Tracking).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success,error} = json;
				if(success){
					this.packageData = json.data;
				}
				if(error){
					this.packageData = false;
					this.notice.error(error);
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error(e.message || 'Không thể tìm kiếm mã Tracking');
				this.packageData = undefined;
			}).finally(()=>{
				this.loading = false;
			});
		},
		doAddWaitList(){
			this.loading = true;
			const app = this;
			this.qrAddTrack(this.Tracking, this.pendingData).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success,error} = json;
				if(success){
					app.packageData = undefined;
					app.Tracking = "";
					app.Notes = "";
					app.notice.success('Đã thêm Tracking vào hàng chờ !!!');
					//this.$nextTick(() => this.$refs.Tracking.focus())
					//this.$refs.Tracking.focus();
					app.$nextTick(() => app.doFocus)
				}
				if(error){
					this.notice.error(error);
				}
			}).catch(e=>{
				console.error(e);
				this.notice.error(e.message || 'Không thể thêm mã Tracking');
			}).finally(()=>{
				this.loading = false;
			});
		},
		doExport(){
			const app = this;
			app.qrExportTrack(this.Tracking).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					app.notice.success('Đã cập nhật dữ liệu !!!');
					app.packageData = undefined;
					app.Tracking = "";
					//this.$nextTick(() => this.$refs.Tracking.focus())
					//this.$refs.Tracking.focus();
					app.$nextTick(() => app.doFocus)
				}
				if(error){
					app.notice.error(error);
				}
			}).catch(e=>{
				console.log(e);
				app.notice.error(e.message || 'Không thể cập nhật dữ liệu !!!');
			}).finally(()=>{
				this.loading = false;
			})
		},
		doImport(){
			const app = this;
			const {D,R,C,KL,PhiShipNoiDia} = app.packageData;
			app.qrImportTrack(this.Tracking,{D,R,C,KL,PhiShipNoiDia}).then(resp=>{
				return resp.json();
			}).then(json=>{
				const {success, error} = json;
				if(success){
					app.notice.success('Đã cập nhật dữ liệu !!!');
					app.packageData = undefined;
					app.Tracking = "";
					//this.$nextTick(() => this.$refs.Tracking.focus())
					//this.$refs.Tracking.focus();
					app.$nextTick(() => app.doFocus)
				}
				if(error){
					app.notice.error(error);
				}
			}).catch(e=>{
				console.log(e);
				app.notice.error(e.message || 'Không thể cập nhật dữ liệu !!!');
			}).finally(()=>{
				this.loading = false;
			})
		},
		doFocus(){
			if(this.tab === 0) {
				setTimeout(()=>{
					document.getElementById("Tracking").focus()
				},100);
				//document.getElementById("Tracking").focus();
				/*if (this.$refs.Tracking) {
					this.$refs.Tracking.focus();

				}*/
			}
			if(this.tab === 1){
				setTimeout(()=>{
					document.getElementById("TrackingTO").focus();
				},100);
				/*if (this.$refs.TrackingTO) {
					this.$refs.TrackingTO.focus();
				}*/
			}
		}
	},
	watch:{
		tab(){
			this.Tracking = '';
			this.$nextTick(this.doFocus());
		}
	},
	mounted(){
		if(this.loginInfo._perm !== 'admin' && this.loginInfo._perm !== 'warehouse' && this.loginInfo._perm !== 'manager'){
			this.$router.push('/');
			return;
		}
		//this.$nextTick(() => this.doFocus)
		this.$nextTick(this.doFocus());
		//this.doFocus();
		//this.$refs.Tracking.focus();
	}
}
</script>

<style scoped>

</style>